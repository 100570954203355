import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { GET, PATCH } from "@/core/services/store/request.module";
export default {
  name: "profile-product-setting",
  data: function data() {
    return {
      pageLoading: true,
      holdStockList: [{
        value: "quotation",
        text: "Quotation"
      }, {
        value: "job",
        text: "Job"
      }, {
        value: "none",
        text: "None"
      }],
      releaseStockList: [{
        value: "job",
        text: "Job"
      }, {
        value: "invoice",
        text: "Invoice"
      }, {
        value: "none",
        text: "None"
      }],
      setting: {
        prefix: null,
        next_number: null,
        number_length: null,
        description: null
      }
    };
  },
  methods: {
    getProductSetting: function getProductSetting() {
      var _this = this;

      _this.$store.dispatch(GET, {
        url: "setting/product"
      }).then(function (_ref) {
        var data = _ref.data;
        _this.setting = data;
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.pageLoading = false;
      });
    },
    updateProductSetting: function updateProductSetting() {
      var _this = this;

      _this.pageLoading = true;

      _this.$store.dispatch(PATCH, {
        url: "setting/product",
        data: _this.setting
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.pageLoading = false;
      });
    }
  },
  mounted: function mounted() {
    this.getProductSetting();
  }
};