<template>
  <v-container fluid class="width-80" v-if="getPermission('setting:view')">
    <v-layout>
      <v-flex>
        <h3
          class="font-weight-900 mb-4 font-size-36 custom-headline color-custom-blue"
        >
          Visit
        </h3>
      </v-flex>
      <v-flex class="text-right">
        <v-btn
          class="custom-grey-border custom-bold-button"
          v-on:click="goBack"
        >
          <v-icon small left>mdi-keyboard-backspace</v-icon>
          Back
        </v-btn>
      </v-flex>
    </v-layout>
    <perfect-scrollbar
      :options="{ suppressScrollX: true }"
      class="scroll custom-box-top-inner-shadow"
      style="max-height: 85vh; position: relative"
    >
      <v-card flat class="custom-grey-border remove-border-radius">
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="6" class="py-0 my-auto">
              <h3
                class="font-weight-700 custom-headline color-custom-blue"
                style="margin: 3.5px 0px"
              >
                Visit #
              </h3>
            </v-col>
            <template v-if="getPermission('setting:update')">
              <v-col md="6" class="text-right py-0">
                <v-btn
                  small
                  class="text-white"
                  color="cyan"
                  v-on:click="updateJobSetting"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                >
                  <span class="font-size-16 font-weight-600">Update</span>
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-card-title>
        <v-card-text
          class="p-6 font-size-16"
          :class="{ 'pointer-events-none': !getPermission('setting:update') }"
        >
          <v-row>
            <v-col md="4" class="pb-4">
              <label>Prefix</label>
              <v-text-field
                v-model.trim="setting.prefix"
                dense
                filled
                label="Prefix"
                solo
                flat
                hide-details
                :disabled="pageLoading"
                :loading="pageLoading"
                color="cyan"
              ></v-text-field>
            </v-col>
            <v-col md="4" class="pb-4">
              <label>Next Number</label>
              <v-text-field
                v-model.trim="setting.next_number"
                dense
                filled
                label="Next Number"
                solo
                flat
                type="number"
                min="0"
                max="999"
                hide-details
                :disabled="pageLoading"
                :loading="pageLoading"
                color="cyan"
              ></v-text-field>
            </v-col>
            <v-col md="4" class="pb-4">
              <label>Number Length</label>
              <v-text-field
                v-model.trim="setting.number_length"
                dense
                filled
                label="Number Length"
                solo
                flat
                type="number"
                min="0"
                max="15"
                hide-details
                :disabled="pageLoading"
                :loading="pageLoading"
                color="cyan"
              ></v-text-field>
            </v-col>
            <v-col v-if="false" md="6" sm="6" class="pb-4 col-lg-6">
              <label
                >Buffer Time (in minutes)
                <v-tooltip top content-class="custom-top-tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      dense
                      style="position: absolute; top: 10px; margin-left: 3px"
                      >mdi-information-outline</v-icon
                    >
                  </template>
                  <span
                    >You can't assign Job to particular Engineer/Technician
                    before<br />
                    or after for this buffer time of already assigned Job to
                    him.<br />
                    e.g. For Engineer A Job assigned from 10 AM to 11 AM &amp;
                    you<br />
                    kept Buffer time as 30 minutes, then in this case you can
                    not<br />
                    assign another Job to him between 9:30 AM till 11:30
                    AM</span
                  >
                </v-tooltip>
              </label>
              <v-text-field
                v-model.trim="setting.buffer_time"
                dense
                filled
                label="Buffer Time (in minutes)"
                solo
                flat
                type="number"
                min="10"
                max="1440"
                hide-details
                :disabled="pageLoading"
                :loading="pageLoading"
                color="cyan"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card flat class="custom-grey-border remove-border-radius mt-4">
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="4" class="py-0 my-auto">
              <h3
                class="font-weight-700 custom-headline color-custom-blue"
                style="margin: 3.5px 0px"
              >
                Skill
              </h3>
            </v-col>
            <template v-if="getPermission('setting:update')">
              <v-col md="8" class="text-right py-0">
                <v-btn small class="mx-4" v-on:click="createJobType">
                  <span class="font-size-16 font-weight-600">Create Skill</span>
                </v-btn>
                <v-btn
                  small
                  class="text-white"
                  color="cyan"
                  v-on:click="updateJobSetting"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                >
                  <span class="font-size-16 font-weight-600">Update</span>
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-card-title>
        <v-card-text
          class="p-6 font-size-16"
          :class="{ 'pointer-events-none': !getPermission('setting:update') }"
        >
          <v-row>
            <v-col md="12" class="pb-4">
              <table class="width-100 mt-4">
                <tbody>
                  <tr v-for="(type, index) in jobTypeList" :key="index">
                    <td>
                      <v-text-field
                        v-model.trim="type.text"
                        dense
                        filled
                        label="Skill"
                        solo
                        flat
                        class="px-4 py-2"
                        hide-details
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        color="cyan"
                      ></v-text-field>
                    </td>
                    <td width="50">
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-on:click="deleteJobType(index)"
                            color="deep-orange"
                            v-bind="attrs"
                            v-on="on"
                            >mdi-delete</v-icon
                          >
                        </template>
                        <span>Click here to delete</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card flat class="custom-grey-border remove-border-radius mt-4">
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="4" class="py-0 my-auto">
              <h3
                class="font-weight-700 custom-headline color-custom-blue"
                style="margin: 3.5px 0px"
              >
                Visit Descriptions
              </h3>
            </v-col>
            <template v-if="getPermission('setting:update')">
              <v-col md="8" class="text-right py-0">
                <v-btn small class="mx-4" v-on:click="addjobdescription">
                  <span class="font-size-16 font-weight-600"
                    >Create Visit Descriptions</span
                  >
                </v-btn>
                <v-btn
                  small
                  class="text-white"
                  color="cyan"
                  v-on:click="updateJobSetting"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                >
                  <span class="font-size-16 font-weight-600">Update</span>
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-card-title>
        <v-card-text
          class="p-6 font-size-16"
          :class="{ 'pointer-events-none': !getPermission('setting:update') }"
        >
          <v-row>
            <v-col md="12" class="pb-4">
              <table class="width-100 mt-4">
                <tbody>
                  <tr
                    v-for="(type, index) in setting.job_desc_details"
                    :key="index"
                  >
                    <td>
                      <v-text-field
                        v-model.trim="type.name"
                        dense
                        filled
                        label="Visit Descriptions Title"
                        solo
                        flat
                        class="px-4 py-2"
                        hide-details
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        color="cyan"
                      ></v-text-field>
                      <v-textarea
                        v-model.trim="type.description"
                        dense
                        filled
                        label="Visit Descriptions"
                        solo
                        flat
                        class="px-4 py-2"
                        hide-details
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        color="cyan"
                      ></v-textarea>
                    </td>
                    <td width="50">
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-on:click="deletejobdescription(index)"
                            color="deep-orange"
                            v-bind="attrs"
                            v-on="on"
                            >mdi-delete</v-icon
                          >
                        </template>
                        <span>Click here to delete</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card flat class="custom-grey-border remove-border-radius mt-4">
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="4" class="py-0 my-auto">
              <h3
                class="font-weight-700 custom-headline color-custom-blue"
                style="margin: 3.5px 0px"
              >
                Fault Found
              </h3>
            </v-col>
            <template v-if="getPermission('setting:update')">
              <v-col md="8" class="text-right py-0">
                <v-btn small class="mx-4" v-on:click="createFaultFound">
                  <span class="font-size-16 font-weight-600"
                    >Create Fault Found</span
                  >
                </v-btn>
                <v-btn
                  small
                  class="text-white"
                  color="cyan"
                  v-on:click="updateFaultFoundSetting"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                >
                  <span class="font-size-16 font-weight-600">Update</span>
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-card-title>
        <v-card-text
          class="p-6 font-size-16"
          :class="{ 'pointer-events-none': !getPermission('setting:update') }"
        >
          <v-row>
            <v-col md="12" class="pb-4">
              <table class="width-100 mt-4">
                <tbody>
                  <tr v-for="(type, index) in FaultFoundList" :key="index">
                    <td>
                      <v-text-field
                        v-model.trim="type.text"
                        dense
                        filled
                        label="Fault Found"
                        solo
                        flat
                        class="px-4 py-2"
                        hide-details
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        color="cyan"
                      ></v-text-field>
                    </td>
                    <td width="50">
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-on:click="deleteFaultFound(index)"
                            color="deep-orange"
                            v-bind="attrs"
                            v-on="on"
                            v-if="type.text != 'Others'"
                            >mdi-delete</v-icon
                          >
                        </template>
                        <span>Click here to delete</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <!--  -->

      <v-card flat class="custom-grey-border remove-border-radius mt-4">
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="4" class="py-0 my-auto">
              <h3
                class="font-weight-700 custom-headline color-custom-blue"
                style="margin: 3.5px 0px"
              >
                Remedy
              </h3>
            </v-col>
            <template v-if="getPermission('setting:update')">
              <v-col md="8" class="text-right py-0">
                <v-btn small class="mx-4" v-on:click="adddropdown">
                  <span class="font-size-16 font-weight-600"
                    >Create Remedy</span
                  >
                </v-btn>
                <v-btn
                  small
                  class="text-white"
                  color="cyan"
                  v-on:click="updateVisitMobileSetting"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                >
                  <span class="font-size-16 font-weight-600">Update</span>
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-card-title>
        <v-card-text
          class="p-6 font-size-16"
          :class="{ 'pointer-events-none': !getPermission('setting:update') }"
        >
          <v-row>
            <v-col md="12" class="pb-4">
              <table class="width-100 mt-4">
                <tbody>
                  <tr v-for="(type, index) in job_visit_mobile" :key="index">
                    <td>
                      <v-text-field
                        v-model.trim="type.remedy"
                        dense
                        filled
                        label="Remedy"
                        solo
                        flat
                        class="px-4 py-2"
                        hide-details
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        color="cyan"
                      ></v-text-field>
                    </td>
                    <td width="50">
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-on:click="deleteadddropdown(index)"
                            color="deep-orange"
                            v-bind="attrs"
                            v-on="on"
                            v-if="type.remedy != 'Others'"
                            >mdi-delete</v-icon
                          >
                        </template>
                        <span>Click here to delete</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card flat class="custom-grey-border remove-border-radius mt-4">
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="4" class="py-0 my-auto">
              <h3
                class="font-weight-700 custom-headline color-custom-blue"
                style="margin: 3.5px 0px"
              >
                Recommendation
              </h3>
            </v-col>
            <template v-if="getPermission('setting:update')">
              <v-col md="8" class="text-right py-0">
                <v-btn small class="mx-4" v-on:click="addRecommendation">
                  <span class="font-size-16 font-weight-600"
                    >Create Recommendation</span
                  >
                </v-btn>
                <v-btn
                  small
                  class="text-white"
                  color="cyan"
                  v-on:click="updateRecommendationSetting"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                >
                  <span class="font-size-16 font-weight-600">Update</span>
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-card-title>
        <v-card-text
          class="p-6 font-size-16"
          :class="{ 'pointer-events-none': !getPermission('setting:update') }"
        >
          <v-row>
            <v-col md="12" class="pb-4">
              <table class="width-100 mt-4">
                <tbody>
                  <tr
                    v-for="(type, index) in job_recommendation_mobile"
                    :key="index"
                  >
                    <td>
                      <v-text-field
                        v-model.trim="type.recommendation"
                        dense
                        filled
                        label="Recommendation"
                        solo
                        flat
                        class="px-4 py-2"
                        hide-details
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        color="cyan"
                      ></v-text-field>
                    </td>
                    <td width="50">
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-on:click="deleteRecommendation(index)"
                            color="deep-orange"
                            v-bind="attrs"
                            v-on="on"
                            v-if="type.remedy != 'Others'"
                            >mdi-delete</v-icon
                          >
                        </template>
                        <span>Click here to delete</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card flat class="custom-grey-border remove-border-radius mt-4">
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="4" class="py-0 my-auto">
              <h3
                class="font-weight-700 custom-headline color-custom-blue"
                style="margin: 3.5px 0px"
              >
                Remark
              </h3>
            </v-col>
            <template v-if="getPermission('setting:update')">
              <v-col md="8" class="text-right py-0">
                <v-btn small class="mx-4" v-on:click="addRemark">
                  <span class="font-size-16 font-weight-600"
                    >Create Remark</span
                  >
                </v-btn>
                <v-btn
                  small
                  class="text-white"
                  color="cyan"
                  v-on:click="updateRemarkSetting"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                >
                  <span class="font-size-16 font-weight-600">Update</span>
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-card-title>
        <v-card-text
          class="p-6 font-size-16"
          :class="{ 'pointer-events-none': !getPermission('setting:update') }"
        >
          <v-row>
            <v-col md="12" class="pb-4">
              <table class="width-100 mt-4">
                <tbody>
                  <tr v-for="(type, index) in job_remark_mobile" :key="index">
                    <td>
                      <v-text-field
                        v-model.trim="type.remark"
                        dense
                        filled
                        label="Remark"
                        solo
                        flat
                        class="px-4 py-2"
                        hide-details
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        color="cyan"
                      ></v-text-field>
                    </td>
                    <td width="50">
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-on:click="deleteRemark(index)"
                            color="deep-orange"
                            v-bind="attrs"
                            v-on="on"
                            v-if="type.remedy != 'Others'"
                            >mdi-delete</v-icon
                          >
                        </template>
                        <span>Click here to delete</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card flat class="custom-grey-border remove-border-radius mt-4">
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="4" class="py-0 my-auto">
              <h3
                class="font-weight-700 custom-headline color-custom-blue"
                style="margin: 3.5px 0px"
              >
                Follow up
              </h3>
            </v-col>
            <template v-if="getPermission('setting:update')">
              <v-col md="8" class="text-right py-0">
                <v-btn small class="mx-4" v-on:click="addFollowUp">
                  <span class="font-size-16 font-weight-600"
                    >Create Follow up</span
                  >
                </v-btn>
                <v-btn
                  small
                  class="text-white"
                  color="cyan"
                  v-on:click="updatefollowupSetting"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                >
                  <span class="font-size-16 font-weight-600">Update</span>
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-card-title>
        <v-card-text
          class="p-6 font-size-16"
          :class="{ 'pointer-events-none': !getPermission('setting:update') }"
        >
          <v-row>
            <v-col md="12" class="pb-4">
              <table class="width-100 mt-4">
                <tbody>
                  <tr
                    v-for="(type, index) in job_follow_up_mobile"
                    :key="index"
                  >
                    <td>
                      <v-text-field
                        v-model.trim="type.follow_up"
                        dense
                        filled
                        label="Follow up"
                        solo
                        flat
                        class="px-4 py-2"
                        hide-details
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        color="cyan"
                      ></v-text-field>
                    </td>
                    <td width="50">
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-on:click="deleteFollowUp(index)"
                            color="deep-orange"
                            v-bind="attrs"
                            v-on="on"
                            v-if="type.remedy != 'Others'"
                            >mdi-delete</v-icon
                          >
                        </template>
                        <span>Click here to delete</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <!--  -->

      <v-card
        v-if="false"
        flat
        class="custom-grey-border remove-border-radius mt-4"
      >
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="6" class="py-0 my-auto">
              <h3
                class="font-weight-700 custom-headline color-custom-blue"
                style="margin: 3.5px 0px"
              >
                Job Terms &amp; Conditions
              </h3>
            </v-col>
            <template v-if="getPermission('setting:update')">
              <v-col md="6" class="text-right py-0">
                <v-btn
                  small
                  class="text-white"
                  color="cyan"
                  v-on:click="updateJobSetting"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                >
                  <span class="font-size-16 font-weight-600">Update</span>
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-card-title>
        <v-card-text
          class="p-6 font-size-16"
          :class="{ 'pointer-events-none': !getPermission('setting:update') }"
        >
          <v-row>
            <v-col md="12" class="pb-4">
              <v-textarea
                v-model.trim="setting.terms_conditions"
                auto-grow
                dense
                filled
                label="Terms &amp; Conditions"
                solo
                flat
                row-height="30"
                color="cyan"
                :disabled="pageLoading"
                :loading="pageLoading"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card
        v-if="false"
        flat
        class="custom-grey-border remove-border-radius mt-4"
      >
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="6" class="py-0 my-auto">
              <h3
                class="font-weight-700 custom-headline color-custom-blue"
                style="margin: 3.5px 0px"
              >
                Job Internal Notes
              </h3>
            </v-col>
            <template v-if="getPermission('setting:update')">
              <v-col md="6" class="text-right py-0">
                <v-btn
                  small
                  class="text-white"
                  color="cyan"
                  v-on:click="updateJobSetting"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                >
                  <span class="font-size-16 font-weight-600">Update</span>
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-card-title>
        <v-card-text
          class="p-6 font-size-16"
          :class="{ 'pointer-events-none': !getPermission('setting:update') }"
        >
          <v-row>
            <v-col md="6" class="pb-4">
              <v-textarea
                v-model.trim="setting.admin_note"
                :disabled="pageLoading"
                :loading="pageLoading"
                auto-grow
                dense
                filled
                label="Admin"
                solo
                flat
                row-height="30"
                color="cyan"
              ></v-textarea>
            </v-col>
            <v-col md="6" class="pb-4">
              <v-textarea
                v-model.trim="setting.client_note"
                :disabled="pageLoading"
                :loading="pageLoading"
                auto-grow
                dense
                filled
                label="Client"
                solo
                flat
                row-height="30"
                color="cyan"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </perfect-scrollbar>
  </v-container>
</template>

<script>
import { GET, PATCH } from "@/core/services/store/request.module";

export default {
  name: "profile-job-setting",
  data() {
    return {
      pageLoading: true,
      setting: {
        prefix: null,
        next_number: null,
        number_length: null,
        buffer_time: null,
        terms_conditions: null,
        job_desc_details: [
          {
            name: null,
            description: null,
          },
        ],

        admin_note: null,
        client_note: null,
        job_type: [],
        Fault_Found: [],
      },

      job_visit_mobile: [
        {
          remedy: null,
        },
      ],
      job_recommendation_mobile: [
        {
          recommendation: null,
        },
      ],
      job_remark_mobile: [
        {
          remark: null,
        },
      ],
      job_follow_up_mobile: [
        {
          remark: null,
        },
      ],
      jobTypeList: [
        {
          text: null,
        },
      ],
      FaultFoundList: [
        {
          text: null,
        },
      ],

      job_description_list: [
        {
          text: null,
        },
      ],
    };
  },
  methods: {
    createJobType() {
      this.jobTypeList.push({
        text: null,
      });
    },
    createFaultFound() {
      this.FaultFoundList.push({
        text: null,
      });
    },
    adddropdown() {
      this.job_visit_mobile.push({
        text: null,
      });
    },
    deleteadddropdown(index) {
      if (this.job_visit_mobile.length > 0) {
        this.job_visit_mobile.splice(index, 1);
      }
    },

    addRecommendation() {
      this.job_recommendation_mobile.push({
        recommendation: null,
      });
    },
    deleteRecommendation(index) {
      if (this.job_recommendation_mobile.length > 0) {
        this.job_recommendation_mobile.splice(index, 1);
      }
    },

    addRemark() {
      this.job_remark_mobile.push({
        remark: null,
      });
    },
    deleteRemark(index) {
      if (this.job_remark_mobile.length > 0) {
        this.job_remark_mobile.splice(index, 1);
      }
    },
    addFollowUp() {
      this.job_follow_up_mobile.push({
        follow_up: null,
      });
    },
    deleteFollowUp(index) {
      if (this.job_follow_up_mobile.length > 0) {
        this.job_follow_up_mobile.splice(index, 1);
      }
    },
    deleteJobType(index) {
      if (this.jobTypeList.length > 0) {
        this.jobTypeList.splice(index, 1);
      }
    },
    deleteFaultFound(index) {
      if (this.FaultFoundList.length > 0) {
        this.FaultFoundList.splice(index, 1);
      }
    },

    addjobdescription() {
      this.setting.job_desc_details.push({
        name: null,
        description: null,
      });
    },

    deletejobdescription(index) {
      if (this.setting.job_desc_details.length > 0) {
        this.setting.job_desc_details.splice(index, 1);
      }
    },

    getJobSetting() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "setting/job" })
        .then(({ data }) => {
          _this.setting = data;
          if (data.job_type) {
            _this.jobTypeList = data.job_type.map((row) => {
              return { text: row };
            });
          }
          //  if (data.job_description_list) {
          //     _this.job_description_list = data.job_description_list.map((row) => {
          //       return { text: row };
          //     });
          //   }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },

    getFaultFoundSetting() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "setting/getOptions-fault-found" })
        .then(({ data }) => {
          if (data.fault_found) {
            _this.FaultFoundList = data.fault_found.map((row) => {
              return { text: row.text };
            });
          }
          //  if (data.job_description_list) {
          //     _this.job_description_list = data.job_description_list.map((row) => {
          //       return { text: row };
          //     });
          //   }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },

    getJobDescription() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "setting/job-description" })
        .then(({ data }) => {
          _this.setting.job_desc_details = data;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    updateJobSetting() {
      const _this = this;
      _this.pageLoading = true;
      _this.setting.job_type = _this.jobTypeList.map((row) => {
        return row.text;
      });
      _this.$store
        .dispatch(PATCH, { url: "setting/job", data: _this.setting })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },

    updateVisitMobileSetting() {
      const _this = this;
      _this.pageLoading = true;
      _this.job_visit_mobile = _this.job_visit_mobile.map((row) => {
        return { remedy: row.remedy };
      });
      _this.$store
        .dispatch(PATCH, {
          url: "setting/visit-mobile",
          data: { job_visit_mobile: _this.job_visit_mobile },
        })

        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },

    updateRecommendationSetting() {
      const _this = this;
      _this.pageLoading = true;
      _this.job_recommendation_mobile = _this.job_recommendation_mobile.map(
        (row) => {
          return { recommendation: row.recommendation };
        }
      );
      _this.$store
        .dispatch(PATCH, {
          url: "setting/recommendation-mobile",
          data: { job_recommendation_mobile: _this.job_recommendation_mobile },
        })

        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },

    updateRemarkSetting() {
      const _this = this;
      _this.pageLoading = true;
      _this.job_remark_mobile = _this.job_remark_mobile.map((row) => {
        return { remark: row.remark };
      });
      _this.$store
        .dispatch(PATCH, {
          url: "setting/remark-mobile",
          data: { job_remark_mobile: _this.job_remark_mobile },
        })

        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },

    updatefollowupSetting() {
      const _this = this;
      _this.pageLoading = true;
      _this.job_follow_up_mobile = _this.job_follow_up_mobile.map((row) => {
        return { follow_up: row.follow_up };
      });
      _this.$store
        .dispatch(PATCH, {
          url: "setting/follow_up-mobile",
          data: { job_follow_up_mobile: _this.job_follow_up_mobile },
        })

        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },

    getVisitMobile() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "setting/job_visit_mobile" })
        .then(({ data }) => {
          _this.job_visit_mobile = data;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },

    getRecommendationMobile() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "setting/job_recommendation_mobile" })
        .then(({ data }) => {
          _this.job_recommendation_mobile = data;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },

    getRemarkMobile() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "setting/job_remark_mobile" })
        .then(({ data }) => {
          _this.job_remark_mobile = data;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },

    getfollowUpMobile() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "setting/job_follow_up_mobile" })
        .then(({ data }) => {
          _this.job_follow_up_mobile = data;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },

    updateFaultFoundSetting() {
      const _this = this;
      _this.pageLoading = true;
      _this.setting.faults = _this.FaultFoundList.map((row) => {
        return { title: row.text };
      });
      _this.$store
        .dispatch(PATCH, { url: "setting/fault_found", data: _this.setting })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },

  mounted() {
    this.getJobSetting();
    this.getJobDescription();
    this.getVisitMobile();
    this.getFaultFoundSetting();
    this.getRecommendationMobile();
    this.getRemarkMobile();
    this.getfollowUpMobile();
  },
};
</script>
