import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.regexp.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import DeleteTemplate from "@/view/pages/partials/Delete.vue";
import ValidationMixin from "@/core/plugins/validation-mixin";
import { DELETE } from "@/core/services/store/request.module";
export default {
  mixins: [ValidationMixin],
  props: {
    requestUrl: {
      required: true,
      type: String,
      default: ""
    },
    deleteDialog: {
      required: true,
      type: Boolean,
      default: false
    }
  },
  components: {
    DeleteTemplate: DeleteTemplate
  },
  data: function data() {
    return {
      uniqueId: null,
      deleteValid: true,
      deleteLoading: false,
      deleteValidateText: null
    };
  },
  watch: {
    deleteDialog: function deleteDialog(param) {
      if (!param) {
        this.generateUniqueId();
      }
    }
  },
  methods: {
    deleteItem: function deleteItem() {
      var _this = this;

      if (!_this.$refs.deleteForm.validate()) {
        return false;
      }

      _this.deleteLoading = true;

      _this.$store.dispatch(DELETE, {
        url: _this.requestUrl
      }).then(function () {
        _this.$emit("delete:success", true);
      }).catch(function () {
        _this.$emit("delete:fail", true);
      }).finally(function () {
        _this.deleteLoading = false;
      });
    },
    generateUniqueId: function generateUniqueId() {
      var _this = this;

      _this.deleteValidateText = null;
      _this.uniqueId = _this.lodash.times(10, function () {
        return _this.lodash.random(35).toString(36);
      }).join("");
    }
  },
  mounted: function mounted() {
    this.generateUniqueId();
  }
};