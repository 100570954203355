import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { GET } from "@/core/services/store/request.module";
import RoleDelete from "@/view/pages/partials/Delete-Request-Template.vue";
export default {
  data: function data() {
    return {
      role_action: [],
      roleLoading: false,
      roleDeleteDialog: false,
      roleDeleteRequestURL: "",
      roles: []
    };
  },
  components: {
    RoleDelete: RoleDelete
  },
  methods: {
    deleteConfirmation: function deleteConfirmation(id) {
      this.roleDeleteRequestURL = "role/" + id;
      this.roleDeleteDialog = true;
    },
    getRoles: function getRoles() {
      var _this = this;

      _this.roleLoading = true;

      _this.$store.dispatch(GET, {
        url: "role"
      }).then(function (_ref) {
        var data = _ref.data;
        _this.roles = data;
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.roleLoading = false;
        _this.roleDeleteDialog = false;
        _this.roleDeleteRequestURL = "";
      });
    }
  },
  mounted: function mounted() {
    this.getRoles();
  }
};