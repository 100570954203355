<template>
  <v-container fluid class="width-80" v-if="getPermission('setting:view')">
    <v-layout>
      <v-flex>
        <h3
          class="font-weight-900 mb-4 font-size-36 custom-headline color-custom-blue"
        >
          Invoice
        </h3>
      </v-flex>
      <v-flex class="text-right">
        <v-btn
          class="custom-grey-border custom-bold-button"
          v-on:click="goBack"
        >
          <v-icon small left>mdi-keyboard-backspace</v-icon>
          Back
        </v-btn>
      </v-flex>
    </v-layout>
    <perfect-scrollbar
      :options="{ suppressScrollX: true }"
      class="scroll custom-box-top-inner-shadow"
      style="max-height: 85vh; position: relative"
    >
      <v-card flat class="custom-grey-border remove-border-radius mt-4">
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="6" class="py-0 my-auto">
              <h3
                class="font-weight-700 custom-headline color-custom-blue"
                style="margin: 3.5px 0px"
              >
                Invoice #
              </h3>
            </v-col>
            <template v-if="getPermission('setting:update')">
              <v-col md="6" class="text-right py-0">
                <v-btn small class="mx-4" @click="createInvoiceSetting">
                  <span class="font-size-16 font-weight-600">Add</span>
                </v-btn>
                <v-btn
                  small
                  class="text-white"
                  color="cyan"
                  @click="updateMultipleInvoice"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                >
                  <span class="font-size-16 font-weight-600">Update</span>
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-card-title>
        <v-card-text
          class="p-6 font-size-16"
          :class="{ 'pointer-events-none': !getPermission('setting:update') }"
        >
          <v-row>
            <v-col md="12" class="pb-4">
              <table class="width-100 mt-4">
                <tbody>
                  <tr v-for="(type, index) in invoicetypeList" :key="index">
                    <td>
                      <v-col md="12" class="pb-4">
                        <label>Suffix</label>
                        <v-text-field
                          v-model.trim="type.prefix"
                          dense
                          filled
                          label="Suffix"
                          solo
                          flat
                          hide-details
                          :disabled="pageLoading"
                          :loading="pageLoading"
                          color="cyan"
                        ></v-text-field>
                      </v-col>
                    </td>
                    <td>
                      <v-col md="12" class="pb-4">
                        <label>Next Number</label>
                        <v-text-field
                          v-model.trim="type.next_number"
                          dense
                          filled
                          label="Next Number"
                          solo
                          flat
                          type="number"
                          min="0"
                          max="999"
                          hide-details
                          :disabled="pageLoading"
                          :loading="pageLoading"
                          color="cyan"
                        ></v-text-field>
                      </v-col>
                    </td>
                    <td>
                      <v-col md="12" class="pb-4">
                        <label>Number Length</label>
                        <v-text-field
                          v-model.trim="type.number_length"
                          dense
                          filled
                          label="Number Length"
                          solo
                          flat
                          type="number"
                          min="0"
                          max="15"
                          hide-details
                          :disabled="pageLoading"
                          :loading="pageLoading"
                          color="cyan"
                        ></v-text-field>
                      </v-col>
                    </td>
                    <td width="50">
                      <v-tooltip content-class="custom-top-tooltip" top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            @click="deleteInvoiceSetting(type.id, index)"
                            color="deep-orange"
                          >
                            mdi-delete
                          </v-icon>
                        </template>
                        <span>Click here to delete</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <!--  <v-card flat class="custom-grey-border remove-border-radius">
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="6" class="py-0 my-auto">
              <h3
                class="font-weight-700 custom-headline color-custom-blue"
                style="margin: 3.5px 0px"
              >
                Invoice #
              </h3>
            </v-col>
            <template v-if="getPermission('setting:update')">
              <v-col md="6" class="text-right py-0">
                <v-btn
                  small
                  class="text-white"
                  color="cyan"
                  v-on:click="updateInvoiceSetting"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                >
                  <span class="font-size-16 font-weight-600">Update</span>
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-card-title>
        <v-card-text
          class="p-6 font-size-16"
          :class="{ 'pointer-events-none': !getPermission('setting:update') }"
        >
          <v-row>
            <v-col md="4" class="pb-4">
              <label>Prefix</label>
              <v-text-field
                v-model.trim="setting.prefix"
                dense
                filled
                label="Prefix"
                solo
                flat
                hide-details
                :disabled="pageLoading"
                :loading="pageLoading"
                color="cyan"
              ></v-text-field>
            </v-col>
            <v-col md="4" class="pb-4">
              <label>Next Number</label>
              <v-text-field
                v-model.trim="setting.next_number"
                dense
                filled
                label="Next Number"
                solo
                flat
                type="number"
                min="0"
                max="999"
                hide-details
                :disabled="pageLoading"
                :loading="pageLoading"
                color="cyan"
              ></v-text-field>
            </v-col>
            <v-col md="4" class="pb-4">
              <label>Number Length</label>
              <v-text-field
                v-model.trim="setting.number_length"
                dense
                filled
                label="Number Length"
                solo
                flat
                type="number"
                min="0"
                max="15"
                hide-details
                :disabled="pageLoading"
                :loading="pageLoading"
                color="cyan"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>-->

      <v-card flat class="custom-grey-border remove-border-radius mt-4">
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="4" class="py-0 my-auto">
              <h3
                class="font-weight-700 custom-headline color-custom-blue"
                style="margin: 3.5px 0px"
              >
                Invoice Descriptions
              </h3>
            </v-col>
            <template v-if="getPermission('setting:update')">
              <v-col md="8" class="text-right py-0">
                <v-btn small class="mx-4" v-on:click="addjobdescription">
                  <span class="font-size-16 font-weight-600"
                    >Add Invoice Descriptions</span
                  >
                </v-btn>
                <v-btn
                  small
                  class="text-white"
                  color="cyan"
                  v-on:click="updateInvoiceSetting"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                >
                  <span class="font-size-16 font-weight-600">Update</span>
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-card-title>
        <v-card-text
          class="p-6 font-size-16"
          :class="{ 'pointer-events-none': !getPermission('setting:update') }"
        >
          <v-row>
            <v-col md="12" class="pb-4">
              <table class="width-100 mt-4">
                <tbody>
                  <tr v-for="(type, index) in job_desc_invoice" :key="index">
                    <td>
                      <v-text-field
                        v-model.trim="type.name"
                        dense
                        filled
                        label="Invoice Descriptions Title"
                        solo
                        flat
                        class="px-4 py-2"
                        hide-details
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        color="cyan"
                      ></v-text-field>
                      <v-textarea
                        v-model.trim="type.description"
                        dense
                        filled
                        label="Invoice Descriptions"
                        solo
                        flat
                        class="px-4 py-2"
                        hide-details
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        color="cyan"
                      ></v-textarea>
                    </td>
                    <td width="50">
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-on:click="deletejobdescription(index)"
                            color="deep-orange"
                            v-bind="attrs"
                            v-on="on"
                            >mdi-delete</v-icon
                          >
                        </template>
                        <span>Click here to delete</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card flat class="custom-grey-border remove-border-radius mt-4">
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="6" class="py-0 my-auto">
              <h3
                class="font-weight-700 custom-headline color-custom-blue"
                style="margin: 3.5px 0px"
              >
                Invoice Terms &amp; Conditions
              </h3>
            </v-col>
            <template v-if="getPermission('setting:update')">
              <v-col md="6" class="text-right py-0">
                <v-btn
                  small
                  class="text-white"
                  color="cyan"
                  v-on:click="updateInvoiceSetting"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                >
                  <span class="font-size-16 font-weight-600">Update</span>
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-card-title>
        <v-card-text
          class="p-6 font-size-16"
          :class="{ 'pointer-events-none': !getPermission('setting:update') }"
        >
          <v-row>
            <v-col md="12" class="pb-4">
              <v-textarea
                v-model.trim="setting.terms_conditions"
                auto-grow
                dense
                filled
                label="Terms &amp; Conditions"
                solo
                flat
                :disabled="pageLoading"
                :loading="pageLoading"
                row-height="30"
                color="cyan"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card flat class="custom-grey-border remove-border-radius mt-4">
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="6" class="py-0 my-auto">
              <h3
                class="font-weight-700 custom-headline color-custom-blue"
                style="margin: 3.5px 0px"
              >
                Invoice Internal Notes
              </h3>
            </v-col>
            <template v-if="getPermission('setting:update')">
              <v-col md="6" class="text-right py-0">
                <v-btn
                  small
                  class="text-white"
                  color="cyan"
                  v-on:click="updateInvoiceSetting"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                >
                  <span class="font-size-16 font-weight-600">Update</span>
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-card-title>
        <v-card-text
          class="p-6 font-size-16"
          :class="{ 'pointer-events-none': !getPermission('setting:update') }"
        >
          <v-row>
            <v-col md="6" class="pb-4">
              <v-textarea
                v-model.trim="setting.admin_note"
                auto-grow
                dense
                filled
                label="Admin"
                solo
                flat
                :disabled="pageLoading"
                :loading="pageLoading"
                row-height="30"
                color="cyan"
              ></v-textarea>
            </v-col>
            <v-col md="6" class="pb-4">
              <v-textarea
                v-model.trim="setting.client_note"
                auto-grow
                dense
                filled
                label="Client"
                solo
                flat
                :disabled="pageLoading"
                :loading="pageLoading"
                row-height="30"
                color="cyan"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </perfect-scrollbar>
  </v-container>
</template>

<script>
import { GET, PATCH, PUT, DELETE } from "@/core/services/store/request.module";

export default {
  name: "profile-invoice-setting",
  data() {
    return {
      pageLoading: true,
      setting: {
        prefix: null,
        next_number: null,
        number_length: null,
        terms_conditions: null,
        job_desc_invoice: new Array(),
        admin_note: null,
        client_note: null,
      },
      job_desc_invoice: [
        {
          name: null,
          description: null,
        },
      ],
      job_type: [],
      invoicetypeList: [
        {
          text: null,
        },
      ],
    };
  },
  methods: {
    createInvoiceSetting() {
      this.invoicetypeList.push({
        text: null,
      });
    },
    addjobdescription() {
      this.job_desc_invoice.push({
        name: null,
        description: null,
      });
    },
    deletejobdescription(index) {
      if (this.job_desc_invoice.length > 0) {
        this.job_desc_invoice.splice(index, 1);
      }
    },

    deleteInvoiceSetting(categoriesid, index) {
      if (categoriesid) {
        this.deleteSettingLeave(categoriesid, index);
      } else {
        this.deleteLeaveDay(index);
      }
    },
    deleteLeaveDay(index) {
      if (this.invoicetypeList.length > 1) {
        this.invoicetypeList.splice(index, 1);
      }
    },
    deleteSettingLeave(categoriesid, index) {
      const _this = this;
      _this.$store
        .dispatch(DELETE, {
          url: "invoice/setting/" + categoriesid,
        })
        .then(() => {
          if (_this.invoicetypeList.length > 0) {
            _this.invoicetypeList.splice(index, 1);
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    getInvoiceSetting() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "setting/invoice" })
        .then(({ data }) => {
          _this.setting = data;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },

    getJobDescription() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "setting/job_desc_invoice" })
        .then(({ data }) => {
          _this.job_desc_invoice = data;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    getMultipleInvoice() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "invoice/setting" })
        .then(({ data }) => {
          _this.invoicetypeList = data;
          if (data.InvoiceSettings) {
            _this.invoicetypeList = data.InvoiceSettings.map((row) => {
              return { text: row.text };
            });
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },

    updateInvoiceSetting() {
      const _this = this;
      _this.setting.job_desc_invoice = _this.job_desc_invoice;
      _this.pageLoading = true;
      _this.$store
        .dispatch(PATCH, { url: "setting/invoice", data: _this.setting })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    updateMultipleInvoice() {
      const _this = this;
      // console.log(_this.setting);
      _this.pageLoading = true;
      _this.invoicetypeList.InvoiceSetting = _this.invoicetypeList.map(
        (row) => {
          return row.text;
        }
      );
      _this.$store
        .dispatch(PUT, {
          url: "invoice/setting",
          data: { invoicesetting: _this.invoicetypeList },
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },

  mounted() {
    this.getInvoiceSetting();
    this.getJobDescription();
    this.getMultipleInvoice();
  },
};
</script>
