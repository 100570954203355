<template>
  <v-container fluid class="width-80" v-if="getPermission('setting:view')">
    <v-layout>
      <v-flex>
        <h3
          class="font-weight-900 mb-4 font-size-36 custom-headline color-custom-blue"
        >
          Account
        </h3>
      </v-flex>
      <v-flex class="text-right">
        <v-btn
          class="custom-grey-border custom-bold-button"
          v-on:click="goBack"
        >
          <v-icon small left>mdi-keyboard-backspace</v-icon>
          Back
        </v-btn>
      </v-flex>
    </v-layout>
    <perfect-scrollbar
      :options="{ suppressScrollX: true }"
      class="scroll custom-box-top-inner-shadow"
      style="max-height: 85vh; position: relative"
    >
      <v-card flat class="custom-grey-border remove-border-radius">
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="6" class="py-0 my-auto">
              <h3
                class="font-weight-700 custom-headline color-custom-blue"
                style="margin: 3.5px 0px"
              >
                Account #
              </h3>
            </v-col>
            <template v-if="getPermission('setting:update')">
              <v-col md="6" class="text-right py-0">
                <v-btn
                  small
                  class="text-white"
                  color="cyan"
                  v-on:click="updateAccountSetting"
                >
                  <span class="font-size-16 font-weight-600">Update</span>
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-card-title>
        <v-card-text
          class="p-6 font-size-16"
          :class="{ 'pointer-events-none': !getPermission('setting:update') }"
        >
          <v-row>
            <v-col md="6" class="pb-4">
              <label>Name</label>
              <v-text-field
                v-model.trim="setting.name"
                dense
                filled
                label="Name"
                solo
                flat
                :disabled="pageLoading"
                :loading="pageLoading"
                hide-details
                color="cyan"
              ></v-text-field>
            </v-col>
            <v-col md="6" class="pb-4">
              <label>Code</label>
              <v-text-field
                v-model.trim="setting.code"
                dense
                filled
                label="Code"
                solo
                flat
                :disabled="pageLoading"
                :loading="pageLoading"
                min="0"
                max="10"
                hide-details
                color="cyan"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="12" class="pb-4">
              <label>Description</label>
              <v-textarea
                v-model.trim="setting.description"
                auto-grow
                dense
                filled
                label="Description"
                solo
                flat
                :disabled="pageLoading"
                :loading="pageLoading"
                row-height="30"
                color="cyan"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </perfect-scrollbar>
  </v-container>
</template>

<script>
import { GET, PUT } from "@/core/services/store/request.module";

export default {
  name: "profile-account-setting",
  data() {
    return {
      pageLoading: true,
      accountId: null,
      setting: {
        name: null,
        code: null,
        description: null,
      },
    };
  },

  methods: {
    getAccountSetting() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "setting/account" })
        .then(({ data }) => {
          _this.setting = data;
          _this.accountId = data.id;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },

    updateAccountSetting() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(PUT, {
          url: "setting/account/" + this.accountId,
          data: _this.setting,
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  mounted() {
    this.getAccountSetting();
  },
};
</script>
