import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { GET, PATCH } from "@/core/services/store/request.module";
import Dialog from "@/view/pages/partials/Dialog.vue";
import DateTimePicker from "@/view/pages/partials/DateTimePicker.vue";
export default {
  name: "profile-service-form-setting",
  data: function data() {
    return {
      pageLoading: true,
      detailDialog: false,
      tableRows: [],
      serviceForm: null,
      attributes: {
        checklist: {
          label: "Check List",
          checkbox: [{
            label: "Clean Motor & Controller Compartment",
            value: null
          }, {
            label: "Clean Frame & Console Panel",
            value: null
          }, {
            label: "Check & Test Console For Abnormality",
            value: null
          }, {
            label: "Clean & Lubricate Running Deck",
            value: null
          }, {
            label: "Check Running Belt Alignment & Adjust Accordingly",
            value: null
          }, {
            label: "Check Running Belt Tightness & Adjust if Necessary",
            value: null
          }]
        },
        textarea_one: {
          label: "Service Rendered",
          value: null
        },
        textarea_two: {
          label: "Engineer Remarks",
          value: null
        },
        dates: {
          started_at: {
            label: "Started At",
            value: null
          },
          finished_at: {
            label: "Finished At",
            value: null
          }
        },
        terms_conditions: {
          label: "Terms & Conditions",
          value: null
        }
      }
    };
  },
  methods: {
    showDetail: function showDetail(_ref) {
      var id = _ref.id,
          attributes = _ref.attributes;
      this.detailDialog = true;
      this.serviceForm = id;
      this.attributes = attributes;
    },
    getServiceFormSetting: function getServiceFormSetting() {
      var _this = this;

      _this.$store.dispatch(GET, {
        url: "setting/service-form"
      }).then(function (_ref2) {
        var data = _ref2.data;
        _this.tableRows = data;
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.pageLoading = false;
      });
    },
    updateServiceFormSetting: function updateServiceFormSetting() {
      var _this = this;

      _this.pageLoading = true;

      _this.$store.dispatch(PATCH, {
        url: "setting/service-form/" + _this.serviceForm,
        data: {
          attributes: _this.attributes
        }
      }).then(function (_ref3) {
        var data = _ref3.data;
        _this.tableRows = data;
        _this.detailDialog = false;
        _this.serviceForm = null;
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.pageLoading = false;
      });
    }
  },
  components: {
    DateTimePicker: DateTimePicker,
    Dialog: Dialog
  },
  mounted: function mounted() {
    this.getServiceFormSetting();
  }
};