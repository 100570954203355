import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import CommonMixin from "@/core/plugins/common-mixin";
import moment from "moment-timezone";
import { QUERY, POST } from "@/core/services/store/request.module";
import Dialog from "@/view/pages/partials/Dialog.vue";
import DatePicker from "@/view/pages/partials/Datepicker.vue";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);
export default {
  mixins: [CommonMixin],
  data: function data() {
    return {
      createHolidayLoading: false,
      holidayDialog: false,
      pageLoading: false,
      holidayLoading: false,
      year: moment().format("YYYY"),
      holidays: [],
      holidayData: {
        date: new Date().toISOString().substr(0, 10),
        description: null,
        type: 1,
        working: 1
      },
      yearList: [{
        value: moment().subtract(2, "year").format("YYYY"),
        text: moment().subtract(2, "year").format("YYYY")
      }, {
        value: moment().subtract(1, "year").format("YYYY"),
        text: moment().subtract(1, "year").format("YYYY")
      }, {
        value: moment().format("YYYY"),
        text: moment().format("YYYY")
      }, {
        value: moment().add(1, "year").format("YYYY"),
        text: moment().add(1, "year").format("YYYY")
      }, {
        value: moment().add(2, "year").format("YYYY"),
        text: moment().add(2, "year").format("YYYY")
      }]
    };
  },
  watch: {
    holidayDialog: function holidayDialog() {
      this.holidayData = {
        date: new Date().toISOString().substr(0, 10),
        description: null,
        type: 1,
        working: 1
      };
    }
  },
  components: {
    Dialog: Dialog,
    DatePicker: DatePicker
  },
  methods: {
    CreateHoliday: function CreateHoliday() {
      var _this = this;

      _this.createHolidayLoading = true;

      _this.$store.dispatch(POST, {
        url: "holiday",
        data: _this.holidayData
      }).then(function () {
        _this.getHolidays();
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.createHolidayLoading = false;
      });
    },
    setHolidayDate: function setHolidayDate(param) {
      this.holidayData.date = param;
    },
    updateHoliday: function updateHoliday(param) {
      var _this = this;

      _this.holidayDialog = true;
      setTimeout(function () {
        _this.holidayData = {
          id: param.id,
          date: param.date,
          description: param.description,
          type: param.type,
          working: param.working ? 1 : 0
        };
      }, 500);
    },
    getHolidays: function getHolidays() {
      var _this = this;

      _this.holidayLoading = true;

      _this.$store.dispatch(QUERY, {
        url: "holiday",
        data: {
          year: _this.year
        }
      }).then(function (_ref) {
        var data = _ref.data;
        _this.holidays = data;
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.holidayLoading = false;
        _this.holidayDialog = false;
      });
    }
  },
  mounted: function mounted() {
    this.getHolidays();
  }
};