<template>
  <v-container fluid class="width-80" v-if="getPermission('setting:view')">
    <v-layout>
      <v-flex>
        <h3
          class="font-weight-900 mb-4 font-size-36 custom-headline color-custom-blue"
        >
          Quotation
        </h3>
      </v-flex>
      <v-flex class="text-right">
        <v-btn
          class="custom-grey-border custom-bold-button"
          v-on:click="goBack"
        >
          <v-icon small left>mdi-keyboard-backspace</v-icon>
          Back
        </v-btn>
      </v-flex>
    </v-layout>
    <perfect-scrollbar
      :options="{ suppressScrollX: true }"
      class="scroll custom-box-top-inner-shadow"
      style="max-height: 85vh; position: relative"
    >
      <v-card flat class="custom-grey-border remove-border-radius">
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="6" class="py-0 my-auto">
              <h3
                class="font-weight-700 custom-headline color-custom-blue"
                style="margin: 3.5px 0px"
              >
                Quotation #
              </h3>
            </v-col>
            <template v-if="getPermission('setting:update')">
              <v-col md="6" class="text-right py-0">
                <v-btn
                  small
                  class="text-white"
                  color="cyan"
                  v-on:click="updateQuotationSetting"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                >
                  <span class="font-size-16 font-weight-600">Update</span>
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-card-title>
        <v-card-text
          class="p-6 font-size-16"
          :class="{ 'pointer-events-none': !getPermission('setting:update') }"
        >
          <v-row>
            <v-col md="6" class="pb-4 col-lg-3">
              <label>Prefix</label>
              <v-text-field
                v-model.trim="setting.prefix"
                dense
                filled
                label="Prefix"
                solo
                flat
                hide-details
                :disabled="pageLoading"
                :loading="pageLoading"
                color="cyan"
              ></v-text-field>
            </v-col>
            <v-col md="6" class="pb-4 col-lg-3">
              <label>Next Number</label>
              <v-text-field
                v-model.trim="setting.next_number"
                dense
                filled
                label="Next Number"
                solo
                flat
                type="number"
                min="0"
                max="999"
                hide-details
                :disabled="pageLoading"
                :loading="pageLoading"
                color="cyan"
              ></v-text-field>
            </v-col>
            <v-col md="6" class="pb-4 col-lg-3">
              <label>Number Length</label>
              <v-text-field
                v-model.trim="setting.number_length"
                dense
                filled
                label="Number Length"
                solo
                flat
                type="number"
                min="0"
                max="15"
                hide-details
                :disabled="pageLoading"
                :loading="pageLoading"
                color="cyan"
              ></v-text-field>
            </v-col>
            <v-col md="6" class="pb-4 col-lg-3">
              <label>Open Days</label>
              <v-text-field
                v-model.trim="setting.quotation_open_days"
                dense
                filled
                label="Open Days"
                solo
                flat
                type="number"
                min="0"
                max="15"
                hide-details
                v-mask="'####'"
                :disabled="pageLoading"
                :loading="pageLoading"
                color="cyan"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card flat class="custom-grey-border remove-border-radius mt-4">
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="4" class="py-0 my-auto">
              <h3
                class="font-weight-700 custom-headline color-custom-blue"
                style="margin: 3.5px 0px"
              >
                Quotation Descriptions
              </h3>
            </v-col>
            <template v-if="getPermission('setting:update')">
              <v-col md="8" class="text-right py-0">
                <v-btn small class="mx-4" v-on:click="addjobdescription">
                  <span class="font-size-16 font-weight-600"
                    >Add Quotation Descriptions</span
                  >
                </v-btn>
                <v-btn
                  small
                  class="text-white"
                  color="cyan"
                  v-on:click="updateQuotationSetting"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                >
                  <span class="font-size-16 font-weight-600">Update</span>
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-card-title>
        <v-card-text
          class="p-6 font-size-16"
          :class="{ 'pointer-events-none': !getPermission('setting:update') }"
        >
          <v-row>
            <v-col md="12" class="pb-4">
              <table class="width-100 mt-4">
                <tbody>
                  <tr
                    v-for="(type, index) in job_desc_quota_invoice"
                    :key="index"
                  >
                    <td>
                      <v-text-field
                        v-model.trim="type.name"
                        dense
                        filled
                        label="Quotation Descriptions Title"
                        solo
                        flat
                        class="px-4 py-2"
                        hide-details
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        color="cyan"
                      ></v-text-field>
                      <v-textarea
                        v-model.trim="type.description"
                        dense
                        filled
                        label="Quotation Descriptions"
                        solo
                        flat
                        class="px-4 py-2"
                        hide-details
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        color="cyan"
                      ></v-textarea>
                    </td>
                    <td width="50">
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-on:click="deletejobdescription(index)"
                            color="deep-orange"
                            v-bind="attrs"
                            v-on="on"
                            >mdi-delete</v-icon
                          >
                        </template>
                        <span>Click here to delete</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card flat class="custom-grey-border remove-border-radius mt-4">
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="5" class="py-0 my-auto">
              <h3
                class="font-weight-700 custom-headline color-custom-blue"
                style="margin: 3.5px 0px"
              >
                Quotation Terms &amp; Conditions
              </h3>
            </v-col>
            <template v-if="getPermission('setting:update')">
              <v-col md="7" class="text-right py-0">
                <v-btn
                  small
                  class="text-white"
                  color="cyan"
                  v-on:click="updateQuotationSetting"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                >
                  <span class="font-size-16 font-weight-600">Update</span>
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-card-title>
        <v-card-text
          class="p-6 font-size-16"
          :class="{ 'pointer-events-none': !getPermission('setting:update') }"
        >
          <v-row>
            <v-col md="12" class="pb-4">
              <v-textarea
                v-model.trim="setting.terms_conditions"
                auto-grow
                dense
                filled
                label="Terms &amp; Conditions"
                solo
                flat
                :disabled="pageLoading"
                :loading="pageLoading"
                row-height="30"
                color="cyan"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card flat class="custom-grey-border remove-border-radius mt-4">
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="6" class="py-0 my-auto">
              <h3
                class="font-weight-700 custom-headline color-custom-blue"
                style="margin: 3.5px 0px"
              >
                Quotation Internal Notes
              </h3>
            </v-col>
            <v-col md="6" class="text-right py-0">
              <v-btn
                small
                class="text-white"
                color="cyan"
                v-on:click="updateQuotationSetting"
                :disabled="pageLoading"
                :loading="pageLoading"
              >
                <span class="font-size-16 font-weight-600">Update</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="p-6 font-size-16">
          <v-row>
            <v-col md="6" class="pb-4">
              <v-textarea
                v-model.trim="setting.admin_note"
                auto-grow
                dense
                filled
                label="Admin"
                solo
                flat
                :disabled="pageLoading"
                :loading="pageLoading"
                row-height="30"
                color="cyan"
              ></v-textarea>
            </v-col>
            <v-col md="6" class="pb-4">
              <v-textarea
                v-model.trim="setting.client_note"
                auto-grow
                dense
                filled
                label="Client"
                solo
                flat
                :disabled="pageLoading"
                :loading="pageLoading"
                row-height="30"
                color="cyan"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </perfect-scrollbar>
  </v-container>
</template>

<script>
import { GET, PATCH } from "@/core/services/store/request.module";

export default {
  name: "profile-quotation-setting",
  data() {
    return {
      pageLoading: true,
      setting: {
        prefix: null,
        next_number: null,
        number_length: null,
        quotation_open_days: null,
        terms_conditions: null,
        job_desc_quota_invoice: new Array(),
        admin_note: null,
        client_note: null,
      },
      job_desc_quota_invoice: [
        {
          name: null,
          description: null,
        },
      ],
      job_type: [],
    };
  },
  methods: {
    createJobType() {
      this.jobTypeList.push({
        text: null,
      });
    },
    addjobdescription() {
      this.job_desc_quota_invoice.push({
        name: null,
        description: null,
      });
    },
    deletejobdescription(index) {
      if (this.job_desc_quota_invoice.length > 0) {
        this.job_desc_quota_invoice.splice(index, 1);
      }
    },

    deleteJobType(index) {
      if (this.jobTypeList.length > 1) {
        this.jobTypeList.splice(index, 1);
      }
    },
    getQuotationSetting() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "setting/quotation" })
        .then(({ data }) => {
          _this.setting = data;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },

    /*  getJobSetting() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "setting/job" })
        .then(({ data }) => {
           _this.setting = data;
          if (data.job_type) {
            _this.jobTypeList = data.job_type.map((row) => {
              return { text: row };
            });
          }
        //  if (data.job_description_list) {
        //     _this.job_description_list = data.job_description_list.map((row) => {
        //       return { text: row };
        //     });
        //   }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    }, */

    getJobDescription() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "setting/job_desc_quota_invoice" })
        .then(({ data }) => {
          _this.job_desc_quota_invoice = data;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },

    /* updateJobSetting() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(PATCH, { url: "setting/job", data:  _this.job_desc_quota_invoice })
        .then(({ data }) => {
          _this.newData = data;

        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    }, */

    updateQuotationSetting() {
      const _this = this;
      _this.setting.job_desc_quota_invoice = _this.job_desc_quota_invoice;
      _this.pageLoading = true;
      _this.$store
        .dispatch(PATCH, { url: "setting/quotation", data: _this.setting })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  mounted() {
    // this.getJobSetting();
    this.getJobDescription();
    this.getQuotationSetting();
  },
};
</script>
