<template>
  <v-app>
    <v-container fluid class="width-80">
      <v-layout>
        <v-flex>
          <h3
            class="font-weight-900 mb-4 font-size-36 custom-headline color-custom-blue"
          >
            Integrations
          </h3>
        </v-flex>
        <v-flex class="text-right">
          <v-btn
            class="custom-grey-border custom-bold-button"
            v-on:click="goBack"
          >
            <v-icon small left>mdi-keyboard-backspace</v-icon>
            Back
          </v-btn>
        </v-flex>
      </v-layout>
      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll custom-box-top-inner-shadow"
        style="max-height: 70vh; position: relative"
      >
        <v-card flat class="custom-grey-border remove-border-radius mt-4">
          <v-card-title class="headline grey lighten-4">
            <v-row>
              <v-col md="12" class="py-0 my-auto">
                <h3
                  class="font-weight-700 custom-headline color-custom-blue"
                  style="margin: 3.5px 0px"
                >
                  Configuration
                </h3>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text class="p-6 font-size-16">
            <!--  <v-row>
              <v-col md="2">
                <v-img
                  :lazy-src="$defaultImage"
                  :src="$assetURL('media/logos/google-calendar.png')"
                  max-height="100"
                  contain
                  position="center center"
                ></v-img>
              </v-col>
              <v-col md="10" class="setting-configuration">
                <div>
                  <h4 class="font-weight-bold color-custom-blue">
                    Google Calendar
                  </h4>
                  <p>
                    Integrate with Google Calendar - Automatic sync all visits and
                    reminders.
                  </p>
                </div>
                <div>
                  <v-btn
                    v-if="googleConnected"
                    v-on:click="googleDisconnect"
                    :loading="googleLoading"
                    :disabled="googleLoading || pageLoading"
                    small
                    class="text-white"
                    color="cyan"
                  >
                    <span class="font-size-16 font-weight-600">Disconnect</span>
                  </v-btn>
                  <v-btn
                    v-else
                    v-on:click="googleConnect"
                    small
                    class="text-white"
                    color="cyan"
                    :loading="googleLoading"
                    :disabled="googleLoading || pageLoading"
                  >
                    <span class="font-size-16 font-weight-600">Connect</span>
                  </v-btn>
                </div>
              </v-col> 
            </v-row> -->
            <v-row>
              <v-col md="2">
                <v-img
                  :lazy-src="$defaultImage"
                  :src="$assetURL('media/logos/xero.png')"
                  max-height="100"
                  contain
                  position="center center"
                ></v-img>
              </v-col>
              <v-col md="10" class="setting-configuration">
                <div>
                  <h4 class="font-weight-bold color-custom-blue">XERO</h4>
                  <p>
                    Integrate with Xero - Automatic sync all Products, Services,
                    Customers, Payments and Invoices.
                  </p>
                </div>
                <div>
                  <v-btn
                    v-if="xeroConnected"
                    v-on:click="xeroDisconnect"
                    :loading="xeroLoading"
                    :disabled="xeroLoading || pageLoading"
                    small
                    class="text-white"
                    color="cyan"
                  >
                    <span class="font-size-16 font-weight-600">Disconnect</span>
                  </v-btn>
                  <v-btn
                    v-else
                    v-on:click="xeroConnect"
                    small
                    class="text-white"
                    color="cyan"
                    :loading="xeroLoading"
                    :disabled="xeroLoading || pageLoading"
                  >
                    <span class="font-size-16 font-weight-600">Connect</span>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </perfect-scrollbar>
    </v-container>
    <Dialog :commonDialog="xeroDataDialog">
      <template v-slot:title> Update/Create Xero </template>
      <template v-slot:body>
        <v-form
          ref="xeroVerificationForm"
          lazy-validation
          v-on:submit.stop.prevent="updateXero"
        >
          <v-row>
            <v-col md="6">
              <label class="font-size-16 font-weight-500 required">
                Client ID
              </label>
              <v-text-field
                dense
                filled
                color="cyan"
                label="Client Id"
                solo
                flat
                :rules="[
                  validateRules.required(xeroDetails.client_id, 'Client Id'),
                ]"
                class="pl-2 width-100"
                v-model.trim="xeroDetails.client_id"
              ></v-text-field>
            </v-col>
            <v-col md="6">
              <label class="font-size-16 font-weight-500 required">
                Secret ID
              </label>
              <v-text-field
                dense
                filled
                color="cyan"
                label="Secret Id"
                solo
                flat
                :rules="[
                  validateRules.required(xeroDetails.secret_id, 'Secret Id'),
                ]"
                class="pl-2 width-100"
                v-model.trim="xeroDetails.secret_id"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="6">
              <label class="font-size-16 font-weight-500 required">
                Landing URI
              </label>
              <v-text-field
                dense
                filled
                color="cyan"
                label="Landing URI"
                solo
                flat
                :rules="[
                  validateRules.required(
                    xeroDetails.landing_uri,
                    'Landing URI'
                  ),
                ]"
                class="pl-2 width-100"
                v-model.trim="xeroDetails.landing_uri"
              ></v-text-field>
            </v-col>
            <v-col md="6">
              <label class="font-size-16 font-weight-500 required">
                Redirect URI
              </label>
              <v-text-field
                dense
                filled
                color="cyan"
                label="Redirect URI"
                solo
                flat
                :rules="[
                  validateRules.required(
                    xeroDetails.redirect_uri,
                    'Redirect URI'
                  ),
                ]"
                class="pl-2 width-100"
                v-model.trim="xeroDetails.redirect_uri"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="xeroLoading"
          :loading="xeroLoading"
          class="mx-2 custom-grey-border custom-bold-button white--text"
          color="cyan"
          v-on:click="updateXero"
        >
          Submit
        </v-btn>
        <v-btn
          :disabled="xeroLoading"
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="xeroDataDialog = false"
        >
          Cancel
        </v-btn>
      </template>
    </Dialog>
  </v-app>
</template>

<script>
import { GET, POST, DELETE } from "@/core/services/store/request.module";
import Dialog from "@/view/pages/partials/Dialog.vue";
import ValidationMixin from "@/core/plugins/validation-mixin";

export default {
  mixins: [ValidationMixin],
  data() {
    return {
      googleConnected: false,
      xeroConnected: false,
      googlePopup: null,
      googleTimeInterval: null,
      googleLoading: false,
      xeroDataDialog: false,
      xeroLoading: false,
      xeroDetails: {
        client_id: null,
        secret_id: null,
        landing_uri: null,
        redirect_uri: null,
      },
      pageLoading: false,
    };
  },
  components: {
    Dialog,
  },
  methods: {
    updateXero() {
      const _this = this;

      if (!_this.$refs.xeroVerificationForm.validate()) {
        return false;
      }

      _this.formLoading = true;
      _this.isVerified = false;

      _this.validationErrorBag = new Array();

      _this.$store
        .dispatch(POST, {
          url: "xero",
          data: _this.xeroDetails,
        })
        .then(() => {
          _this.xeroDataDialog = false;
          const url = `${this.$apiURL}xero-connect`;
          window.open(url, "_blank");
        })
        .catch(({ response }) => {
          console.log(response);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    googleConnect() {
      const _this = this;
      _this.googleLoading = true;
      _this.$store
        .dispatch(GET, { url: "google/calendar/url" })
        .then(({ data }) => {
          _this.googleConnectPopup(data.url);
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    xeroConnect() {
      const _this = this;
      _this.xeroLoading = true;
      _this.$store
        .dispatch(GET, { url: "xero" })
        .then(({ data }) => {
          if (data && data.client_id && data.secret_id && data.redirect_uri) {
            const url = `${this.$apiURL}xero-connect`;
            window.open(url, "_blank");
          } else {
            _this.xeroDataDialog = true;
          }
          _this.xeroLoading = false;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    googleDisconnect() {
      const _this = this;
      _this.googleLoading = true;
      _this.$store
        .dispatch(GET, { url: "google/calendar/disconnect" })
        .then(() => {
          _this.googleConnected = false;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.googleLoading = false;
        });
    },
    xeroDisconnect() {
      const _this = this;
      _this.googleLoading = true;
      _this.$store
        .dispatch(DELETE, { url: "xero-token" })
        .then(() => {
          _this.xeroConnected = false;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.googleLoading = false;
        });
    },
    googleConnectPopup(url) {
      let left = screen.width / 2 - 536 / 2;
      let top = screen.height / 2 - 634 / 2;
      this.googlePopup = window.open(
        url,
        "",
        "resizable=yes,top=" + top + ",left=" + left + ",width=536,height=634"
      );
      this.watchGooglePopup();
    },

    checkXeroStatus() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "xero-token" })
        .then((data) => {
          if (data.status) {
            _this.xeroConnected = true;
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.googleLoading = false;
        });
    },
    watchGooglePopup() {
      const _this = this;
      clearInterval(_this.googleTimeInterval);
      _this.googleTimeInterval = setInterval(function () {
        if (_this.googlePopup && _this.googlePopup.closed) {
          _this.googlePopup = null;
          clearInterval(_this.googleTimeInterval);
        }
      }, 1000);
    },
  },
  mounted() {
    this.checkXeroStatus();
  },
};
</script>
