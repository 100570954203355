import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { GET, POST, PATCH } from "@/core/services/store/request.module";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";
import Dialog from "@/view/pages/partials/Dialog.vue";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ValidationMixin from "@/core/plugins/validation-mixin";
import PhoneTextField from "@/view/pages/partials/PhoneTextField";
import { setCookie, getCookie } from "@/core/plugins/bt-cookie";
export default {
  mixins: [ValidationMixin],
  name: "profile-company-setting",
  data: function data() {
    return {
      formValid: true,
      statusLoading: false,
      pageLoading: true,
      formLoading: false,
      entity_dialog: false,
      entities: [],
      entity: {
        id: null,
        uen_no: null,
        unit_no: null,
        name: null,
        contact_no: null,
        street_1: null,
        street_2: null,
        landmark: null,
        zip_code: null,
        gst: 0,
        gstvalue: null
      }
    };
  },
  methods: {
    reLoginRequired: function reLoginRequired() {
      return !!+getCookie("entity_reload");
    },
    onSubmit: function onSubmit() {
      var _this = this;

      if (!_this.$refs.entityForm.validate()) {
        return false;
      }

      var reqType = POST;
      var reqURL = "setting/entity";

      if (_this.entity.id) {
        reqType = PATCH;
        reqURL = "setting/entity/".concat(_this.entity.id);
      }

      _this.formLoading = true;

      _this.$store.dispatch(reqType, {
        url: reqURL,
        data: _this.entity
      }).then(function (_ref) {
        var data = _ref.data;
        _this.entities = data;
        _this.entity_dialog = false;
        setCookie({
          key: "entity_reload",
          value: 1
        });
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.formLoading = false;
      });
    },
    updateStatus: function updateStatus(id) {
      var _this = this;

      _this.statusLoading = true;

      _this.$store.dispatch(PATCH, {
        url: "setting/entity/".concat(id, "/status")
      }).then(function (_ref2) {
        var data = _ref2.data;
        _this.entities = data;
        setCookie({
          key: "entity_reload",
          value: 1
        });
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.statusLoading = false;
      });
    },
    addEntity: function addEntity() {
      this.entity = new Object({
        id: null,
        uen_no: null,
        unit_no: null,
        name: null,
        contact_no: null,
        street_1: null,
        street_2: null,
        landmark: null,
        zip_code: null,
        gst: 0,
        gstvalue: null
      });
      this.entity_dialog = true;
    },
    updateEntity: function updateEntity(row) {
      this.entity = new Object({
        id: row.id,
        uen_no: row.uen_no,
        unit_no: row.unit_no,
        name: row.name,
        contact_no: row.contact_no,
        street_1: row.street_1,
        street_2: row.street_2,
        landmark: row.landmark,
        zip_code: row.zip_code,
        gst: row.gst,
        gstvalue: row.gstvalue ? row.gstvalue : 0
      });
      this.entity_dialog = true;
    },
    getEntitySetting: function getEntitySetting() {
      var _this = this;

      _this.pageLoading = true;

      _this.$store.dispatch(GET, {
        url: "setting/entity"
      }).then(function (_ref3) {
        var data = _ref3.data;
        _this.entities = data;
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.pageLoading = false;
      });
    }
  },
  components: {
    Dialog: Dialog,
    PhoneTextField: PhoneTextField,
    ListingTable: ListingTable
  },
  mounted: function mounted() {
    new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    this.getEntitySetting();
  }
};