import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import AppConfiguration from "@/core/config/app.config";
import moment from "moment-timezone";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);
var DEFAULT_DATE = "";
var DEFAULT_TIME = "00:00:00";
var DEFAULT_DATE_FORMAT = "YYYY-MM-DD";
var DEFAULT_TIME_FORMAT = "HH:mm:ss";
var DEFAULT_DIALOG_WIDTH = 340;
var DEFAULT_CLEAR_TEXT = "CLEAR";
var DEFAULT_OK_TEXT = "OK";
export default {
  name: "v-datetime-picker",
  model: {
    prop: "datetime",
    event: "input"
  },
  props: {
    datetime: {
      type: [Date, String],
      default: null
    },
    disabled: {
      type: Boolean
    },
    loading: {
      type: Boolean
    },
    label: {
      type: String,
      default: ""
    },
    dialogWidth: {
      type: Number,
      default: DEFAULT_DIALOG_WIDTH
    },
    clearText: {
      type: String,
      default: DEFAULT_CLEAR_TEXT
    },
    okText: {
      type: String,
      default: DEFAULT_OK_TEXT
    },
    textFieldProps: {
      type: Object
    },
    datePickerProps: {
      type: Object
    },
    timePickerProps: {
      type: Object
    }
  },
  data: function data() {
    return {
      display: false,
      activeTab: 0,
      date: DEFAULT_DATE,
      time: DEFAULT_TIME
    };
  },
  mounted: function mounted() {
    this.init();
  },
  computed: {
    dateFormat: function dateFormat() {
      var Config = AppConfiguration.get();

      if (Config) {
        return Config.dateFormat;
      }

      return DEFAULT_DATE_FORMAT;
    },
    timeFormat: function timeFormat() {
      var Config = AppConfiguration.get();

      if (Config) {
        return Config.timeFormat;
      }

      return DEFAULT_TIME_FORMAT;
    },
    dateTimeFormat: function dateTimeFormat() {
      return this.dateFormat + " " + this.timeFormat;
    },
    formattedDatetime: function formattedDatetime() {
      return this.selectedDatetime ? moment(this.selectedDatetime).format(this.dateTimeFormat) : "";
    },
    selectedDatetime: function selectedDatetime() {
      if (this.date && this.time) {
        var datetimeString = this.date + " " + this.time;

        if (this.time.length === 5) {
          datetimeString += ":00";
        }

        return new Date(datetimeString);
      } else {
        return null;
      }
    },
    dateSelected: function dateSelected() {
      return !this.date;
    }
  },
  methods: {
    init: function init() {
      if (!this.datetime) {
        return;
      }

      var initDateTime;

      if (this.datetime instanceof Date) {
        initDateTime = this.datetime;
      } else if (typeof this.datetime === "string" || this.datetime instanceof String) {
        // see https://stackoverflow.com/a/9436948
        initDateTime = moment(this.datetime).format(DEFAULT_DATE_FORMAT + " " + DEFAULT_TIME_FORMAT);
      }

      this.date = moment(initDateTime).format(DEFAULT_DATE_FORMAT);
      this.time = moment(initDateTime).format(DEFAULT_TIME_FORMAT);
    },
    okHandler: function okHandler() {
      this.resetPicker();
      this.$emit("input", this.selectedDatetime);
    },
    clearHandler: function clearHandler() {
      this.resetPicker();
      this.date = DEFAULT_DATE;
      this.time = DEFAULT_TIME;
      this.$emit("input", null);
    },
    resetPicker: function resetPicker() {
      this.display = false;
      this.activeTab = 0;

      if (this.$refs.timer) {
        this.$refs.timer.selectingHour = true;
      }
    },
    showTimePicker: function showTimePicker() {
      this.activeTab = 1;
    }
  },
  watch: {
    datetime: function datetime() {
      this.init();
    }
  }
};