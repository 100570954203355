import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.find.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import moment from "moment-timezone";
import TimePickerTemplate from "@/view/pages/partials/Timepicker";
import { PATCH, QUERY } from "@/core/services/store/request.module";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";
import { getConfig } from "@/core/services/local.service";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);
export default {
  name: "profile-company-setting",
  data: function data() {
    return {
      pageLoading: true,
      entityList: [],
      entity: {},
      setting: {
        company_name: null,
        phone_number: null,
        whatsapp_number: null,
        website_url: null,
        email_address: null,
        street_1: null,
        street_2: null,
        landmark: null,
        zip_code: null,
        date_format: null,
        time_format: null,
        weekday: null
      },
      time_format_list: [{
        value: "HH:mm",
        text: "24 Hour (" + moment().format("HH:mm") + ")"
      }, {
        value: "hh:mm A",
        text: "12 Hour (" + moment().format("hh:mm A") + ")"
      }],
      date_format_list: [{
        value: "MMMM Do YYYY",
        text: moment().format("MMMM Do YYYY")
      }, {
        value: "Do MMMM YYYY",
        text: moment().format("Do MMMM YYYY")
      }, {
        value: "MMM Do YY",
        text: moment().format("MMM Do YY")
      }, {
        value: "Do MMM YY",
        text: moment().format("Do MMM YY")
      }, {
        value: "DD/MM/YYYY",
        text: moment().format("DD/MM/YYYY")
      }, {
        value: "MM/DD/YYYY",
        text: moment().format("MM/DD/YYYY")
      }, {
        value: "YYYY-DD-MM",
        text: moment().format("YYYY-DD-MM")
      }],
      weekdayList: [{
        value: 1,
        text: "Sunday"
      }, {
        value: 2,
        text: "Monday"
      }],
      weekDays: []
    };
  },
  methods: {
    getCompanySetting: function getCompanySetting() {
      var _this = this;

      _this.$store.dispatch(QUERY, {
        url: "setting/company",
        data: {
          entity: this.entity.id
        }
      }).then(function (_ref) {
        var data = _ref.data;
        _this.setting = data;
        _this.weekDays = data.business_hours;
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.pageLoading = false;
      });
    },
    updateCompanySetting: function updateCompanySetting() {
      var _this = this;

      _this.pageLoading = true;
      _this.setting.business_hours = _this.weekDays;
      _this.setting.entity = _this.entity.id;

      _this.$store.dispatch(PATCH, {
        url: "setting/company",
        data: _this.setting
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.pageLoading = false;
      });
    },
    updateTime: function updateTime(type, index, value) {
      if (this.weekDays[index]) {
        if (type == "start") {
          this.weekDays[index].start_time = value;
        }

        if (type == "end") {
          this.weekDays[index].end_time = value;
        }
      }
    },
    updateEntity: function updateEntity(row) {
      this.entity = row;
      this.pageLoading = true;
      this.getCompanySetting();
    }
  },
  components: {
    TimePickerTemplate: TimePickerTemplate
  },
  mounted: function mounted() {
    var _this2 = this;

    new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    this.$nextTick(function () {
      if (_this2.entity) {
        _this2.updateEntity(_this2.entity);
      }
    });
  },
  beforeMount: function beforeMount() {
    var _this3 = this;

    this.entityList = getConfig("entity");
    this.$nextTick(function () {
      _this3.entity = _this3.lodash.find(_this3.entityList, {
        id: 1
      });
    });
  }
};