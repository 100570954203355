import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { GET, PATCH } from "@/core/services/store/request.module";
export default {
  name: "profile-customer-setting",
  data: function data() {
    return {
      pageLoading: true,
      setting: {
        prefix: null,
        next_number: null,
        number_length: null,
        remarks: null
      }
    };
  },
  methods: {
    getCustomerSetting: function getCustomerSetting() {
      var _this = this;

      _this.$store.dispatch(GET, {
        url: "setting/customer"
      }).then(function (_ref) {
        var data = _ref.data;
        _this.setting = data;
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.pageLoading = false;
      });
    },
    updateCustomerSetting: function updateCustomerSetting() {
      var _this = this;

      _this.pageLoading = true;

      _this.$store.dispatch(PATCH, {
        url: "setting/customer",
        data: _this.setting
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.pageLoading = false;
      });
    }
  },
  mounted: function mounted() {
    this.getCustomerSetting();
  }
};